<div class="relevance-feedback">
  <ng-container *ngIf="tender.isRelevant === undefined && state && state === 'loading'">
    <mat-icon svgIcon="loader" class="icon-loader" />
  </ng-container>
  <ng-container *ngIf="tender.isRelevant === undefined && state && state !== 'loading'">
    <mat-icon svgIcon="check-circle-filled" class="icon-success" [hidden]="state === 'negative'" />
    <mat-icon svgIcon="x-circle-filled" class="icon-danger" [hidden]="state === 'positive'"/>
    <span>
      {{ 'tenders.relevance-feedback.submitted' | translate }}
    </span>
    <span class="text-decoration-underline cursor-pointer" (click)="cancelFeedback()">
      {{ 'tenders.relevance-feedback.cancel' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="!state">
    <mat-icon svgIcon="sparks-filled" class="icon-ai"
              [ngbTooltip]="'tenders.badge.suggestion' | translate"
              placement="right auto"
              tooltipClass="tender-suggestion-tooltip"
              [openDelay]="500"
    />
    <ng-container *ngIf="tender.isRelevant === undefined">
      <span class="relevance-question">{{ 'tenders.relevance-feedback.is-relevant' | translate | uppercase }}</span>
      <button class="ex-btn xs ex-btn-ai" (click)="submitFeedback(true)">
        {{ 'tenders.relevance-feedback.yes' | translate | uppercase }}
      </button>
      <button class="ex-btn xs ex-btn-ai" (click)="submitFeedback(false)">
        {{ 'tenders.relevance-feedback.no' | translate | uppercase }}
      </button>
    </ng-container>
    <ng-container *ngIf="tender.isRelevant !== undefined">
      <span *ngIf="tender.isRelevant">{{ 'tenders.relevance-feedback.positive-feedback' | translate }}</span>
      <span *ngIf="!tender.isRelevant">{{ 'tenders.relevance-feedback.negative-feedback' | translate }}</span>
    </ng-container>

  </ng-container>
</div>
