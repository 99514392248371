import { CommonModule, JsonPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonExplainModule, HttpLoaderFactory } from '../common-explain/common-explain.module';
import { TreeviewModule } from '../lib/ngx-treeview';
import { tenderRoutes } from './tenders-routing.module';
import { TendersSearchComponent } from './components/tenders-search/tenders-search.component';
import { TendersWatchComponent } from './components/tenders-watch/tenders-watch.component';
import { TendersComponent } from './components/tenders/tenders.component';
import { TendersHeaderComponent } from './components/tenders-header/tenders-header.component';
import { TendersFilterComponent } from './components/tenders-filter/tenders-filter.component';
import { FilterDateComponent } from './components/filter-date/filter-date.component';
import { FilterTopicComponent } from './components/filter-topic/filter-topic.component';
import { NgbDatepickerModule, NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterTerritoryComponent } from './components/filter-territory/filter-territory.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SimpleModalComponent } from './components/simple-modal/simple-modal.component';
import { MaterialModule } from '../material/material.module';
import { IconModule } from '../shared/icon/icon.module';
import { TenderDetailComponent } from './components/tender-detail/tender-detail.component';
import { TenderOverviewComponent } from './components/tender-overview/tender-overview.component';
import { TenderMetadataComponent } from './components/tender-metadata/tender-metadata.component';
import { TenderSmartGridComponent } from './components/tender-smart-grid/tender-smart-grid.component';
import { TenderPdfsReaderComponent } from './components/tender-pdfs-reader/tender-pdfs-reader.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SKELETON_LINE_PARAMS } from '../../assets/data/ngx-skeleton-loader-config';
import { FilterTypeComponent } from './components/filter-type/filter-type.component';
import { RemainingDaysBadgeComponent } from './components/remaining-days-badge/remaining-days-badge.component';
import { TenderTypeBadgeComponent } from './components/tender-type-badge/tender-type-badge.component';
import { ModalDeleteWatchComponent } from './components/modal-delete-watch/modal-delete-watch.component';
import { ModalShareWatchComponent } from './components/modal-share-watch/modal-share-watch.component';
import { TendersListComponent } from './components/tenders-list/tenders-list.component';
import { DaySwiperComponent } from './components/day-swiper/day-swiper.component';
import { TendersSortComponent } from './components/tenders-sort/tenders-sort.component';
import { InfiniteScrollDirective } from '../common-explain/directives/infinite-scroll.directive';
import { TendersSummaryComponent } from './components/tenders-summary/tenders-summary.component';
import { RelevanceFeedbackComponent } from './components/relevance-feedback/relevance-feedback.component';
import { ModalManageWatchUsersComponent } from './components/modal-manage-watch-users/modal-manage-watch-users.component';
import { ModalUnsubcribeWatchComponent } from './components/modal-unsubcribe-watch/modal-unsubcribe-watch.component';
import { TenderStatusSelectorComponent } from './components/tender-status/tender-status-selector.component';
import { TextFieldModule } from "@angular/cdk/text-field";
import { TendersBoardComponent } from './components/tenders-board/tenders-board.component';
import { FilterStatusComponent } from './components/filter-status/filter-status.component';
import { TenderShareBtnComponent } from './components/tender-share-btn/tender-share-btn.component';
import { TenderGridQuestionFormComponent } from './components/tender-grid-question-form/tender-grid-question-form';
import { ModalConfirmationWithCheckbox } from '../components/modal-confirmation-with-checkbox/modal-confirmation-with-checkbox';

@NgModule({
  declarations: [
    TendersSearchComponent,
    TendersComponent,
    TendersHeaderComponent,
    FilterDateComponent,
    FilterTopicComponent,
    FilterTerritoryComponent,
    TendersFilterComponent,
    SimpleModalComponent,
    TenderDetailComponent,
    TenderOverviewComponent,
    TenderMetadataComponent,
    TenderSmartGridComponent,
    TenderPdfsReaderComponent,
    FilterTypeComponent,
    RemainingDaysBadgeComponent,
    TenderTypeBadgeComponent,
    TendersWatchComponent,
    ModalDeleteWatchComponent,
    ModalShareWatchComponent,
    TendersListComponent,
    DaySwiperComponent,
    TendersSortComponent,
    TendersSummaryComponent,
    RelevanceFeedbackComponent,
    ModalManageWatchUsersComponent,
    ModalUnsubcribeWatchComponent,
    TenderGridQuestionFormComponent,
    ModalConfirmationWithCheckbox,
    TendersBoardComponent,
    FilterStatusComponent,
    TenderStatusSelectorComponent,
    TenderShareBtnComponent,
  ],
  exports: [
  ],
  imports: [
    RouterModule.forChild(tenderRoutes),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonExplainModule,
    FormsModule,
    TreeviewModule,
    NgbTooltip,
    NgbDatepickerModule,
    JsonPipe,
    FormsModule,
    NgbTooltipModule,
    CommonModule,
    MaterialModule,
    IconModule,
    NgxExtendedPdfViewerModule,
    NgxSkeletonLoaderModule.forRoot(SKELETON_LINE_PARAMS),
    ReactiveFormsModule,
    InfiniteScrollDirective,
    TextFieldModule,
  ],
    bootstrap: [TendersSearchComponent]
})
export class TendersModule { }


