<div [hidden]="!isVisible" class="grid-question-form">
  <div class="title">
    {{action == 'add' ? ('tenders.tender-detail.private-question-form.add-title' | translate) :
    action == 'edit' ?
      ('tenders.tender-detail.private-question-form.edit-title' | translate) : ''}}
  </div>
  <div class="description">
    {{action == 'add' ? ('tenders.tender-detail.private-question-form.add-description' | translate)
    : action == 'edit' ?
      ('tenders.tender-detail.private-question-form.edit-description' | translate) : ''}}
  </div>
  <div class="question">
    {{'tenders.tender-detail.private-question-form.question' | translate | uppercase}}
    <textarea
      class="question-textarea name"
      type="text"
      maxlength="500"
      placeholder="{{'tenders.tender-detail.private-question-form.question-placeholder' | translate}}"
      [(ngModel)]="name"
    ></textarea>
  </div>
  <div class="question">
    {{'tenders.tender-detail.private-question-form.displayed-name' | translate | uppercase}}
    <textarea
      class="question-textarea displayed-name"
      type="text"
      maxlength="50"
      placeholder="{{'tenders.tender-detail.private-question-form.displayed-name-placeholder' | translate}}"
      [(ngModel)]="displayedName"
    ></textarea>
    <div class="char-count">{{ displayedName?.length || 0 }}/50</div>
  </div>
  <div class="action">
    <button class="ex-btn-danger ex-btn"
            [disabled]="loading"
            (click)="openDeleteConfirmationModal()"
            [ngClass]="{'hidden': action === 'add'}"
    >
      {{ 'tenders.tender-detail.private-question-form.delete' | translate }}
    </button>

    <div class="add-edit-cancel">
      <button class="ex-btn-optional-transparent ex-btn"
              [disabled]="loading"
              (click)="close()"
      >
        {{ 'tenders.tender-detail.private-question-form.cancel' | translate }}
      </button>
      <button *ngIf="action === 'add'"
              class="ex-btn-strong ex-btn"
              [disabled]="!name || !displayedName || loading"
              (click)="addQuestion()"
      >
        {{ !loading ? ('tenders.tender-detail.private-question-form.add' | translate) : '' }}
        <span *ngIf="loading" role="status" aria-hidden="true" class="spinner">
                    <img src="assets/images/icon-loader.svg" height="16" width="16">
                </span>
      </button>
      <button *ngIf="action === 'edit'"
              class="ex-btn-strong ex-btn"
              [disabled]="!name || !displayedName || loading"
              (click)="openEditConfirmationModal()"
      >
        {{ !loading ? ('tenders.tender-detail.private-question-form.edit' | translate) : '' }}
        <span *ngIf="loading" role="status" aria-hidden="true" class="spinner">
                    <img src="assets/images/icon-loader.svg" height="16" width="16">
                </span>
      </button>
    </div>
  </div>

</div>

<ng-template #errorInAddingQuestionToast>
  <app-toast-error
    [title]="'tenders.tender-detail.private-question-form.add-error-title' | translate"
    [text]="'tenders.tender-detail.private-question-form.add-error-text' | translate">
  </app-toast-error>
</ng-template>
<ng-template #errorInEditingQuestionToast>
  <app-toast-error
    [title]="'tenders.tender-detail.private-question-form.edit-error-title' | translate"
    [text]="'tenders.tender-detail.private-question-form.edit-error-text' | translate">
  </app-toast-error>
</ng-template>
<ng-template #successInEditingQuestionToast>
  <div class="toast-container position-relative d-flex w-100 h-100">
    <div class="fw-bold align-self-center toast-text pdr-16px">
      {{'tenders.tender-detail.private-question-form.edit-success-title' |translate}}
    </div>
  </div>
</ng-template>
<ng-template #successInDeletingQuestionToast>
  <div class="toast-container position-relative d-flex w-100 h-100">
    <div class="fw-bold align-self-center toast-text pdr-16px">
      {{'tenders.tender-detail.private-question-form.delete-success-title' |translate}}
    </div>
  </div>
</ng-template>

