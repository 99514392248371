import { Tender} from '../../models/tender';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TendersFilters, TendersSearchService } from '../../services/tenders-search.service';
import { AutoUnsubscribe } from '../../../common-explain/decorators/auto-unsubscribe';
import { TenderEntityService } from "../../services/tender-entity.service";
import { Router } from "@angular/router";
import { TendersModuleService } from "../../services/tenders-module.service";

@Component({
  selector: 'app-tenders-search',
  templateUrl: './tenders-search.component.html',
  styleUrls: ['./tenders-search.component.scss']
})
@AutoUnsubscribe
export class TendersSearchComponent implements OnInit, OnDestroy {
  tenders?: Tender[] = new Array<Tender>();
  tenderId = '';
  totalCount = 0;
  currentPage = 1;
  activeMode!: string;

  constructor(public tenderSearchService: TendersSearchService,
              private tenderEntityService: TenderEntityService,
              private tendersModuleService: TendersModuleService,
              public router: Router
  ) {
    this.updateActiveMode();
  }

  async ngOnInit() {
    this.tendersModuleService.registerService(this.tenderSearchService);
    this.tenderEntityService.tenderId$
      .subscribe((tenderId) => {
        this.tenderId = tenderId;
      });

    this.tenderSearchService._tenders$
      .subscribe((res) => {
        this.updateSearchValues(res?.data, res?.total_count);
      })
  }

  ngOnDestroy() {
    this.tenderSearchService.resetSubjects();
    this.tendersModuleService.unregisterService(this.tenderSearchService);
  }

  get inSearch() {
    return this.tenderSearchService.inSearch;
  }

  get nbPages() {
    return this.tenderSearchService.nbPages;
  }

  updateActiveMode() {
    this.activeMode = this.tenderSearchService.activeMode;
  }

  updateSearchValues(tenders?: Tender[], totalCount?: number) {
    this.tenders = tenders;
    this.updateActiveMode();
    this.totalCount = totalCount ?? 0;
    this.tenderSearchService.nbPages = (Math.ceil(this.totalCount / this.tenderSearchService.limit));
  }

  get filters(): TendersFilters {
    return this.tenderSearchService._filters$.value ?? {};
  }
}
