<div class="day-swiper">
  <button class="day-swiper-button" (click)="prevDay()"
          [disabled]="compareDateDays(lowerDate, date)">
    <mat-icon svgIcon="chevron-left"/>
  </button>
  <div class="day-swiper-label">
    <span class="ds-weekday">{{getWeekDayString()}}</span>
    <span class="ds-day">{{getDayString()}}</span>
    <span class="ds-month">{{getMonthString()}}</span>
  </div>
  <button class="day-swiper-button" (click)="nextDay()" [disabled]="willBeFuture()">
    <mat-icon svgIcon="chevron-right"/>
  </button>
</div>
