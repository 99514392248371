import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isSameDay } from "../../../shared/helpers/date-helper";

@Component({
  selector: 'app-day-swiper',
  templateUrl: './day-swiper.component.html',
  styleUrls: ['./day-swiper.component.scss']
})
export class DaySwiperComponent implements OnChanges {
  @Input() defaultDate?: string;
  @Input() lowerBoundaryDate?: string;
  @Input() editMode?: boolean = false;
  @Input() disabled?: boolean = false;

  date: Date;
  lowerDate: Date;

  @Output() daySelect = new EventEmitter<Date>();

  constructor() {
    this.date = new Date();
    this.lowerDate = new Date();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultDate'] && this.defaultDate) {
      this.date = new Date(this.defaultDate);
    }
    if (changes['lowerBoundaryDate'] && this.lowerBoundaryDate) {
      this.lowerDate = new Date(this.lowerBoundaryDate);
    }
  }

  compareDateDays(date1: Date, date2: Date) {
    return !this.editMode && isSameDay(date1, date2);
  }

  updatePeriod() {
    this.daySelect.emit(this.date);
  }

  nextDay() {
    if (this.willBeFuture()) return;
    this.date.setDate(this.date.getDate() + 1);
    this.updatePeriod();
  }

  prevDay() {
    this.date.setDate(this.date.getDate() - 1);
    this.updatePeriod();
  }

  getWeekDayString() {
    return this.date.toLocaleDateString('fr-FR', { weekday: 'short' });
  }

  getDayString() {
    return this.date.toLocaleDateString('fr-FR', { day: 'numeric' });
  }

  getMonthString() {
    return this.date.toLocaleDateString('fr-FR', { month: 'short' });
  }

  willBeFuture() {
    const tomorrow = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1);
    return tomorrow > new Date();
  }
}
