import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { Tender } from '../../models/tender';
import { StatusItem } from '../../models/tender-status';
import { DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { TendersModuleService } from '../../services/tenders-module.service';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';

@Component({
  selector: 'app-tender-status',
  templateUrl: './tender-status-selector.component.html',
  styleUrls: ['./tender-status-selector.component.scss']
})
export class TenderStatusSelectorComponent implements OnChanges, OnInit {
  @Input({required: true}) tender!: Tender;
  @Input() tenderStatus?: StatusItem;
  @Input() size: 'small' | 'x-small' = 'x-small';
  @Output() statusChange = new EventEmitter<StatusItem>();
  @ViewChild('toast', { read: TemplateRef }) toast!: TemplateRef<any>;

  item?: DropdownItem<StatusItem>
  items: DropdownItem<StatusItem>[] = [];
  forceItem$ = new EventEmitter<DropdownItem | null | undefined>();
  annotationId?: number;

  constructor(private apiTenderService: ApiTenderService,
              private tendersModuleService: TendersModuleService,
              private toastMessageStackService: ToastMessageStackService) {
  }

  ngOnInit() {
    this.items = this.tendersModuleService.statusItems;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tender'] && this.tender.status !== undefined) {
      this.setItem(
        this.tender.status.id ?
          this.toDropdownItem(this.tender.status) :
          null
      );
    }
    if (changes['tenderStatus'] && this.tenderStatus) {
      this.setItem(this.toDropdownItem(this.tenderStatus));
    }
  }

  setItem(status: DropdownItem<StatusItem> | null) {
    this.item = status ?
      this.tendersModuleService.statusItems.find((item) => item.value.id === status?.value.id) :
      undefined
    this.forceItem$.emit(this.item);
  }

  toDropdownItem(status: StatusItem): DropdownItem {
    return {
      label: status?.displayName ?? '',
      value: status ?? {id: 0}
    }
  }

  async onDropdownToggle(event: boolean) {
    if (event) {
      const updatedAnnotation = await this.apiTenderService.annotation
        .getAnnotation(this.tender.id)
        .catch((error) => {
          if (error.status === 404) return undefined;
        });
      if (updatedAnnotation === undefined) return;
      this.annotationId = updatedAnnotation.id;
      this.setItem(this.toDropdownItem({id: updatedAnnotation.status?.id ?? 0}));
      this.tender.status = this.items.find((item) =>
        item.value.id === updatedAnnotation.status?.id)?.value ?? {id: 0};
    }
  }

  async updateAnnotationValue(event?: DropdownItem<StatusItem> | null) {
    if (event) {
      this.statusChange.emit(event.value);
      if (this.annotationId) {
        await this.apiTenderService.annotation.updateAnnotation({
          id: this.annotationId,
          status_id: event.value.id
        }).then(() => {
          this.setItem(event)
          this.tender.status = event.value;
        }).catch(
          () => this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'})
        );
      } else {
        await this.apiTenderService.annotation.addAnnotation({
          tender_uid: this.tender.id,
          status_id: event.value.id,
        }).then((res) => {
          if (res.id === null) this.forceItem$.emit(undefined);
          this.setItem(event)
          this.tender.status = event.value;
          this.annotationId = res.id;
        }).catch(
          () => this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'})
        );
      }
    }
  }

  async removeStatus(event: any) {
    event.stopPropagation();
    await this.apiTenderService.annotation.updateAnnotation({
      id: this.annotationId ?? 0,
      status_id: null
    }).catch(() => {
        this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'});
      });
    this.setItem(null);
    this.tender.status = undefined;
    this.forceItem$.emit(undefined);
  }


}
