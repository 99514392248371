<ex-dropdown
  [items]="items"
  chevronVisibility="hover"
  [headerBackgroundColor]="item?.value?.color ? 'color-mix(in srgb,' + item?.value?.color + ' 20%, transparent)' : 'var(--basic-100)'"
  [headerColor]=item?.value?.color
  [forceItem$]="forceItem$"
  [size]="size"
  noInput
  noBorder
  (change)="updateAnnotationValue($event)"
  (dropdownToggle)="onDropdownToggle($event)"
  container="body"
  [overlayPositions]="[
    {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
    {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
    {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
    {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'}
  ]"
  paddedItemsContainer
>

  <ng-template exDropdownDisplay>
    <div class="status-dropdown-display ls-m1"
         [ngClass]="{'sm': size === 'small', 'xs': size === 'x-small' }">
      <div *ngIf="item?.value?.id"
           [style.color]="item?.value?.color"
           class="font-12px flex-center-center"
            >
        {{ item?.value?.displayName }}
      </div>
      <div *ngIf="!item?.value?.id" class="d-flex align-items-center gap-2 color-basic-800">
        <i class="ph ph-target"></i>
        <div class="font-12px fw-600">{{'tenders.status.go-no-go' | translate}}</div>
      </div>
    </div>

  </ng-template>

  <ng-template exDropdownItem
               let-elm>
    <div class="status-item d-flex align-items-center justify-content-start">
      <div class="color-square flex-center-start">
        <svg width="16" height="16" fill="currentColor"
             [style.color]="elm.value.color">
          <path d="M12 14.5H4C2.89733 14.5 2 13.6027 2 12.5V4.5C2 3.39733 2.89733 2.5 4 2.5H12C13.1027 2.5 14 3.39733 14 4.5V12.5C14 13.6027 13.1027 14.5 12 14.5Z" fill="currentColor"/>
        </svg>
      </div>
      <div class="flex-center-between w-100">
        <div>{{ elm.label }}</div>
        <div *ngIf="elm.value.id === item?.value?.id"
             class="remove-status-btn"
             (click)="removeStatus($event)">
          <i class="ph ph-x">
          </i>
        </div>
      </div>
    </div>

  </ng-template>

</ex-dropdown>


<ng-template #toast let-toast="toast" let-toastService="toastService">
  <div class="position-relative toast-container d-flex w-100 h-100">
    <div class="d-flex">
      <div class="d-flex toast-content">
        <div class="d-flex h-100 flex-grow-0">
          <img class="align-items-center justify-content-center"
               src="../../../../assets/images/icon-alert-triangle-white.svg"
               alt="icon-alert">
        </div>
        <div class="flex-grow-1 align-items-center">
          <p class="m-0 fw-bold">{{ 'tenders.status.toast-content' | translate:({name: item?.value?.displayName})}}</p>
        </div>
      </div>
      <i class="ph ph-x" (click)="toastService.remove(toast)"></i>
    </div>
  </div>
</ng-template>
