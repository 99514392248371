import { NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  declarations: [],
  imports: [
    MaterialModule
  ]
})
export class IconModule {
  private path = '../../../assets/images';

  constructor(
    private sanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry) {
    this.matIconRegistry
      .addSvgIcon('close-panel', this.setPath(`${this.path}/close-share-panel.svg`))
      .addSvgIcon('chevron-left', this.setPath(`${this.path}/chevron-left.svg`))
      .addSvgIcon('chevron-right', this.setPath(`${this.path}/chevron-right.svg`))
      .addSvgIcon('info', this.setPath(`${this.path}/info.svg`))
      .addSvgIcon('chevron-up', this.setPath(`${this.path}/icon-chevron-up.svg`))
      .addSvgIcon('chevron-down', this.setPath(`${this.path}/icon-chevron-down.svg`))
      .addSvgIcon('alert-triangle-fill', this.setPath(`${this.path}/alert-triangle-fill.svg`))
      .addSvgIcon('close-circle', this.setPath(`${this.path}/icon-close-circle.svg`))
      .addSvgIcon('close-circle-fill', this.setPath(`${this.path}/27-icon-close-circle-fill.svg`))
      .addSvgIcon('icon-person-fill', this.setPath(`${this.path}/icon-person-fill.svg`))
      .addSvgIcon('share-doc-icon', this.setPath(`${this.path}/share-icon.svg`))
      .addSvgIcon('save-folder-icon', this.setPath(`${this.path}/27-icon-folder-fill.svg`))
      .addSvgIcon('saved-folder-icon-white', this.setPath(`${this.path}/27-icon-folder-saved.svg`))
      .addSvgIcon('saved-folder-icon-green', this.setPath(`${this.path}/27-icon-folder-saved-green.svg`))
      .addSvgIcon('add-folder-icon', this.setPath(`${this.path}/27-icon-folder-add-fill.svg`))
      .addSvgIcon('add-folder-icon-primary-500', this.setPath(`${this.path}/27-icon-folder-add-fill-primary-500.svg`))
      .addSvgIcon('edit-icon', this.setPath(`${this.path}/icon-edit.svg`))
      .addSvgIcon('trash-icon', this.setPath(`${this.path}/27-icon-trash-2.svg`))
      .addSvgIcon('picture-icon', this.setPath(`${this.path}/27-icon-image-fill.svg`))
      .addSvgIcon('arrow-up', this.setPath(`${this.path}/arrow-up.svg`))
      .addSvgIcon('arrowhead-left-icon', this.setPath(`${this.path}/arrowhead-left.svg`))
      .addSvgIcon('arrowhead-right-icon', this.setPath(`${this.path}/arrowhead-right.svg`))
      .addSvgIcon('arrow-ios-left-icon', this.setPath(`${this.path}/arrow-ios-left.svg`))
      .addSvgIcon('arrow-ios-right-icon', this.setPath(`${this.path}/arrow-ios-right.svg`))
      .addSvgIcon('arrow-ios-up', this.setPath(`${this.path}/arrow-ios-up.svg`))
      .addSvgIcon('arrow-ios-down', this.setPath(`${this.path}/arrow-ios-down.svg`))
      .addSvgIcon('plus-circle-icon', this.setPath(`${this.path}/icon-plus-circle.svg`))
      .addSvgIcon('plus-circle-icon-fill', this.setPath(`${this.path}/27-icon-plus-circle-fill.svg`))
      .addSvgIcon('minus-circle-icon', this.setPath(`${this.path}/icon-minus-circle.svg`))
      .addSvgIcon('arrow-circle-right-fill', this.setPath(`${this.path}/27-icon-arrow-circle-right-fill.svg`))
      .addSvgIcon('arrow-circle-left-fill', this.setPath(`${this.path}/27-icon-arrow-circle-left-fill.svg`))
      .addSvgIcon('article', this.setPath(`${this.path}/27-icon-article.svg`))
      .addSvgIcon('calendar', this.setPath(`${this.path}/27-icon-calendar.svg`))
      .addSvgIcon('calendar-basic-600', this.setPath(`${this.path}/calendar-basic-600.svg`))
      .addSvgIcon('website', this.setPath(`${this.path}/27-icon-globe.svg`))
      .addSvgIcon('search', this.setPath(`${this.path}/27-icon-search.svg`))
      .addSvgIcon('docs', this.setPath(`${this.path}/docs-basic-600.svg`))
      .addSvgIcon('docs-metadata', this.setPath(`${this.path}/icon-doc-metadata.svg`))
      .addSvgIcon('docs-primary-500', this.setPath(`${this.path}/27-icon-docs-primary-500.svg`))
      .addSvgIcon('docs-basic-600', this.setPath(`${this.path}/docs-basic-600.svg`))
      .addSvgIcon('pin', this.setPath(`${this.path}/27-icon-pin.svg`))
      .addSvgIcon('pin-basic-600', this.setPath(`${this.path}/27-icon-pin-basic-600.svg`))
      .addSvgIcon('share-white', this.setPath(`${this.path}/27-icon-share-white.svg`))
      .addSvgIcon('export-white', this.setPath(`${this.path}/27-icon-export-white.svg`))
      .addSvgIcon('hide-600', this.setPath(`${this.path}/27-icon-minus-circle-600.svg`))
      .addSvgIcon('help', this.setPath(`${this.path}/27-icon-question-mark-circle.svg`))
      .addSvgIcon('external-link', this.setPath(`${this.path}/27-icon-external-link.svg`))
      .addSvgIcon('icon-close', this.setPath(`${this.path}/27-icon-close.svg`))
      .addSvgIcon('pagination-left-basic-800', this.setPath(`${this.path}/btn-pagination-gauche.svg`))
      .addSvgIcon('pagination-right-basic-800', this.setPath(`${this.path}/btn-pagination-droite.svg`))
      .addSvgIcon('refresh', this.setPath(`${this.path}/refresh.svg`))
      .addSvgIcon('refresh-basic-800', this.setPath(`${this.path}/27-icon-refresh-basic-800.svg`))
      .addSvgIcon('menu-arrow-basic-800', this.setPath(`${this.path}/27-icon-menu-arrow-basic-800.svg`))
      .addSvgIcon('edit-800', this.setPath(`${this.path}/27-icon-search-ctrl-f.svg`))
      .addSvgIcon('edit-500', this.setPath(`${this.path}/27-icon-search-ctrl-f-500.svg`))
      .addSvgIcon('close-800', this.setPath(`${this.path}/27-icon-close-circle-800.svg`))
      .addSvgIcon('close-500', this.setPath(`${this.path}/27-icon-close-circle-500.svg`))
      .addSvgIcon('close-fill-800', this.setPath(`${this.path}/27-icon-close-circle-fill-800.svg`))
      .addSvgIcon('close-fill-500', this.setPath(`${this.path}/27-icon-close-circle-fill-500.svg`))
      .addSvgIcon('stamp-basic-800', this.setPath(`${this.path}/stamp-basic-800.svg`))
      .addSvgIcon('hand-shake-basic-800', this.setPath(`${this.path}/hand-shake-basic-800.svg`))
      .addSvgIcon('city-basic-800', this.setPath(`${this.path}/city-basic-800.svg`))
      .addSvgIcon('chevron-right-basic-600', this.setPath(`${this.path}/chevron-right-600.svg`))
      .addSvgIcon('double-chevron-down-white', this.setPath(`${this.path}/27-icon-double-chevron-down-white.svg`))
      .addSvgIcon('ext-link-primary-500', this.setPath(`${this.path}/external-link-primary-500.svg`))
      .addSvgIcon('ext-link-basic-800', this.setPath(`${this.path}/ext-link-basic-800.svg`))
      .addSvgIcon('folder-outline-basic-600', this.setPath(`${this.path}/folder-outline-basic-600.svg`))
      .addSvgIcon('tenders-arrow-down', this.setPath(`${this.path}/tenders-arrow-down.svg`))
      .addSvgIcon('tenders-arrow-up-down', this.setPath(`${this.path}/tenders-arrow-up-down.svg`))
      .addSvgIcon('open-html', this.setPath(`${this.path}/open-html-icon.svg`))
      .addSvgIcon('calendar-filled', this.setPath(`${this.path}/calendar-filled-basic-600.svg`))
      .addSvgIcon('inquirers-filled', this.setPath(`${this.path}/inquirers-filled-basic-600.svg`))
      .addSvgIcon('ext-link', this.setPath(`${this.path}/ext-link-basic-600.svg`))
      .addSvgIcon('tender-location-filled', this.setPath(`${this.path}/pin-filled-basic-600.svg`))
      .addSvgIcon('briefcase-filled', this.setPath(`${this.path}/briefcase.svg`))
      .addSvgIcon('open-new-tab', this.setPath(`${this.path}/open-new-tab.svg`))
      .addSvgIcon('plus-filled', this.setPath(`${this.path}/plus-filled-basic-600.svg`))
      .addSvgIcon('dw-dce', this.setPath(`${this.path}/download-dce-white.svg`))
      .addSvgIcon('download', this.setPath(`${this.path}/download.svg`))
      .addSvgIcon('globe', this.setPath(`${this.path}/globe.svg`))
      .addSvgIcon('timer-filled', this.setPath(`${this.path}/timer-filled-basic-800.svg`))
      .addSvgIcon('timer-no-danger', this.setPath(`${this.path}/timer-no-danger.svg`))
      .addSvgIcon('light-bulb-filled', this.setPath(`${this.path}/light-bulb-filled.svg`))
      .addSvgIcon('timer-danger', this.setPath(`${this.path}/timer-danger.svg`))
      .addSvgIcon('folder-plus', this.setPath(`${this.path}/folder-icon-empty-with-plus-on-it.svg`))
      .addSvgIcon('folder-minus', this.setPath(`${this.path}/folder-icon-empty-with-minus-on-it.svg`))
      .addSvgIcon('file-unknown', this.setPath(`${this.path}/file-icon-unknown.svg`))
      .addSvgIcon('file-pdf', this.setPath(`${this.path}/file-icon-pdf.svg`))
      .addSvgIcon('file-excel', this.setPath(`${this.path}/file-icon-excel.svg`))
      .addSvgIcon('file-ppt', this.setPath(`${this.path}/file-icon-ppt.svg`))
      .addSvgIcon('file-text', this.setPath(`${this.path}/file-icon-text.svg`))
      .addSvgIcon('file-img', this.setPath(`${this.path}/file-icon-img.svg`))
      .addSvgIcon('file-html', this.setPath(`${this.path}/file-icon-html.svg`))
      .addSvgIcon('file-filled', this.setPath(`${this.path}/icon-file-filled.svg`))
      .addSvgIcon('timer-filled', this.setPath(`${this.path}/timer-filled.svg`))
      .addSvgIcon('arrow-left', this.setPath(`${this.path}/arrow-left.svg`))
      .addSvgIcon('arrow-right', this.setPath(`${this.path}/arrow-right.svg`))
      .addSvgIcon('folder-empty-filled', this.setPath(`${this.path}/folder-empty-filled.svg`))
      .addSvgIcon('eye-primary-500', this.setPath(`${this.path}/icon-eye-primary-500.svg`))
      .addSvgIcon('eye-basic-800', this.setPath(`${this.path}/icon-eye-basic-800.svg`))
      .addSvgIcon('info-circle-filled', this.setPath(`${this.path}/info-circle-fill.svg`))
      .addSvgIcon('drag-handle', this.setPath(`${this.path}/drag-handle.svg`))
      .addSvgIcon('plus', this.setPath(`${this.path}/plus.svg`))
      .addSvgIcon('parameters-filled', this.setPath(`${this.path}/parameters-filled.svg`))
      .addSvgIcon('floppy-disk-filled', this.setPath(`${this.path}/floppy-disk-filled.svg`))
      .addSvgIcon('trashcan-filled', this.setPath(`${this.path}/trashcan-filled.svg`))
      .addSvgIcon('pencil-filled', this.setPath(`${this.path}/pencil-filled.svg`))
      .addSvgIcon('close', this.setPath(`${this.path}/close.svg`))
      .addSvgIcon('disk', this.setPath(`${this.path}/disk.svg`))
      .addSvgIcon('x', this.setPath(`${this.path}/x.svg`))
      .addSvgIcon('x-circle', this.setPath(`${this.path}/x-circle.svg`))
      .addSvgIcon('x-circle-filled', this.setPath(`${this.path}/x-circle-filled.svg`))
      .addSvgIcon('check', this.setPath(`${this.path}/check.svg`))
      .addSvgIcon('check-circle', this.setPath(`${this.path}/check-circle.svg`))
      .addSvgIcon('check-circle-filled', this.setPath(`${this.path}/check-circle-filled.svg`))
      .addSvgIcon('loader', this.setPath(`${this.path}/loader.svg`))
      .addSvgIcon('explain-icon', this.setPath(`${this.path}/explain-icon.svg`))
      .addSvgIcon('sparks-filled', this.setPath(`${this.path}/sparks-filled.svg`))
      .addSvgIcon('grid-copy-outline', this.setPath(`${this.path}/icon-copy-outline.svg`))
      .addSvgIcon('grid-copy-filled', this.setPath(`${this.path}/icon-copy-filled.svg`))
      .addSvgIcon('share-filled', this.setPath(`${this.path}/share-filled.svg`))
      .addSvgIcon('star', this.setPath(`${this.path}/star.svg`))
      .addSvgIcon('claping-hands', this.setPath(`${this.path}/claping-hands.svg`))
      .addSvgIcon('binocular-plus', this.setPath(`${this.path}/binocular-plus.svg`))
      .addSvgIcon('add-text', this.setPath(`${this.path}/add-text.svg`))
      .addSvgIcon('warning-circle', this.setPath(`${this.path}/warning-circle.svg`))
      .addSvgIcon('add-message', this.setPath(`${this.path}/add-message.svg`))
      .addSvgIcon('no-calendar', this.setPath(`${this.path}/no-calendar.svg`))
      .addSvgIcon('close-circle-fill', this.setPath(`${this.path}/close-circle-fill.svg`))
      .addSvgIcon('hourglass-up', this.setPath(`${this.path}/hourglass-up.svg`))
      .addSvgIcon('arrow-up-circle', this.setPath(`${this.path}/arrow-up-circle.svg`))
      .addSvgIcon('peoples-fill', this.setPath(`${this.path}/peoples-fill.svg`))
      .addSvgIcon('people-plus-fill', this.setPath(`${this.path}/people-plus-fill.svg`))
      .addSvgIcon('people-minus-fill', this.setPath(`${this.path}/people-minus-fill.svg`))
      .addSvgIcon('user-plus-fill', this.setPath(`${this.path}/user-plus-fill.svg`))
  }

  private setPath(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
