import { Injectable } from '@angular/core';
import { MARKET_WATCHES_LIMIT, MarketWatch, MW_NEW_NAME_DEFAULT_VALUE } from '../models/market-watch';
import { TendersSearchService } from './tenders-search.service';
import { ApiTenderService } from './api/api-tender.service';
import { DbTenderTerritory } from '../models/tender-territory';
import { TenderTopic } from '../models/tender-topic';
import { firstValueFrom, interval, lastValueFrom } from 'rxjs';
import { ExplainModuleEnum } from '../../shared/services/module-manager.service';
import { ApiExStatisticsService } from '../../shared/services/api/ex-statistics/api-ex-statistics.service';
import { isEqual } from 'lodash';
import { TenderEntityService } from './tender-entity.service';
import { WatchUser } from '../../common-explain/components/ex-watch-users-selector/ex-watch-users-selector.component';
import { ActivatedRoute } from '@angular/router';
import { toDayPeriod } from '../../shared/helpers/date-helper';
import { DropdownItem } from '../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { DbStatusItem, StatusItem } from '../models/tender-status';
import { Tender } from "../models/tender";
import { SortDirEnum } from "../../common-explain/models/sort";
import { SortFieldEnum } from "../models/tender-search-body";

export interface MarketWatchManager {
  marketWatch?: MarketWatch;
  newMarketWatchName?: string;
  service: TendersSearchService;
  editMode: boolean;
  creationMode?: boolean;
}


const PRELOAD_CHECK_INTERVAL = 1000;
const PRELOAD_BATCH_SIZE = 2;

@Injectable()
export class TendersModuleService {

  marketWatchManagers: MarketWatchManager[] = [];
  previousManager!: MarketWatchManager;
  currentManager!: MarketWatchManager;
  activeSearchServices: TendersSearchService[] = [];

  tenderTopics!: Promise<TenderTopic[]>;
  watchUsers!: WatchUser[];
  userRegions: Promise<DbTenderTerritory[]>;
  groupAccountStatuses: Promise<{data: Array<DbStatusItem> }>
  statusItems: DropdownItem<StatusItem>[] = [];

  constructor(
    private apiTenderService: ApiTenderService,
    private apiExStatisticsService: ApiExStatisticsService,
    private tenderEntityService: TenderEntityService,
    private route: ActivatedRoute
  ) {
    const userId = +(localStorage.getItem('user_id') ?? 0);
    this.initTenderTopics();
    this.userRegions = lastValueFrom(this.apiTenderService.territory.getRegions()).then(async regions => {
      const userDepartments = (await firstValueFrom(this.apiExStatisticsService.AccountInfoReplaySubject))
        .territories
        .map(territory => territory.code);
      return regions.reduce((acc, region) => {
        region.territories = region.territories?.filter(territory => userDepartments
          .includes(territory.code));
        if (region.territories?.length) acc.push(region);
        return acc;
      }, [] as DbTenderTerritory[]);
    });
    const fields = ['created_at', 'id', 'owner_user_id', 'users', 'name', 'settings_json', 'topics_ids', 'updated_at'];
    const filters = {user_id: userId};
    this.apiTenderService.marketWatch.getUserMarketWatches(fields, filters).then(
      marketWatches => {
        if (!marketWatches.length) return;
        this.marketWatchManagers =
          marketWatches.map(watch => {
            const marketWatch = new MarketWatch(watch);
            const service = new TendersSearchService(this.apiTenderService);
            service.watchFilters = {...marketWatch.watchFilters, market_watch_id: marketWatch.id, propagate: false};
            return {
              marketWatch,
              service,
              editMode: false,
            }
          });
        this.marketWatchManagers.sort((a, b) => new Date(a.marketWatch?.createdAt ?? '').getTime() - new Date(b.marketWatch?.createdAt ?? '').getTime());
        // Ajout initial de tous les services des veilles dans la liste des services actifs.
        this.marketWatchManagers.forEach(manager => this.registerService(manager.service));
        const {id, to_date} = this.route.snapshot.queryParams;
        this.setCurrentManager(this.marketWatchManagers
          .find(manager => manager.marketWatch?.id == +id) ?? this.marketWatchManagers[0]);
        if (to_date) this.currentManager.service.selectedPeriod = toDayPeriod(to_date);
      }
    );
    // Preload clock
    interval(PRELOAD_CHECK_INTERVAL).subscribe(() => {
      if (
        this.marketWatchManagers.some(manager => manager.service.inSearch) ||
        this.tenderEntityService.tenderId$.value
      ) return;
      this.marketWatchManagers
        .filter(manager => !manager.service.tenders && !manager.service.inSearch)
        .slice(0, PRELOAD_BATCH_SIZE).forEach(manager => {
        manager.service.search();
      })
    });

    this.groupAccountStatuses = this.apiTenderService.annotation
      .retrieveGroupAccountStatuses({
        fields: ['id', 'status_name', 'displayed_status_name'],
        filters: {},
        limit: 10
      })
  }

  addManager(marketWatchManager: MarketWatchManager) {
    this.marketWatchManagers.push(marketWatchManager);
    this.registerService(marketWatchManager.service);
  }

  setCurrentManager(marketWatchManager: MarketWatchManager) {
    this.previousManager = this.currentManager;
    this.currentManager = marketWatchManager;
    if (!this.watchUsers) this.initWatchUsers().then();
    if (!this.currentManager.service.tenders && !this.currentManager.service.inSearch) this.currentManager.service.search();
  }

  async initWatchUsers() {
    const fields = ['id', 'is_market_watch_user', 'name', 'email'];
    const filter = {
      market_watch_id: this.currentManager.marketWatch?.id,
      group_account_id: +(localStorage.getItem('group_account_id') ?? 0),
      level_id: 1,
      modules: ['tenders']
    };
    this.watchUsers = await lastValueFrom(this.apiTenderService.marketWatch.getMarketWatchUsers(fields, filter));
  }

  async initTenderTopics() {
    this.tenderTopics = lastValueFrom(this.apiTenderService.topic.getUserTopics(ExplainModuleEnum.TENDERS))

    // Update topics for loaded market watches
    if (this.marketWatchManagers.length) {
      const tenderTopicsIds = (await this.tenderTopics).map(topic => topic.id);
      this.marketWatchManagers.forEach(manager => {
        const settings = manager.service?.watchFilters;
        if (!settings) return;
        const userIsOwner = manager?.marketWatch?.ownerUserId === +(localStorage.getItem('user_id') ?? 0);
        // on ne filtre les thèmes que si l'utilisateur est propriétaire de la veille
        const filteredTopics = settings?.topics_ids?.filter(topic => userIsOwner ? tenderTopicsIds.includes(topic) : true) ?? [];
        if (!isEqual(manager.service.selectedTopics, filteredTopics)) {
          manager.service.watchFilters = {
            ...settings, market_watch_id: manager.marketWatch?.id, topics_ids: filteredTopics
          };
          if (manager.marketWatch) manager.marketWatch.topicsIds = filteredTopics;
        }
      });
    }
  }

  get currentMarketWatchUsers(): WatchUser[] {
    const copy: WatchUser[] = [];
    const watchUsers = this.watchUsers;
    watchUsers?.forEach((user) => {
      copy.push({
        ...user,
        is_market_watch_user: (this.currentManager.marketWatch?.watchUsers ?? []).some(elm => elm.id === user.id)
      })
    });
    return copy;
  }

  onDaySelect(date: Date) {
    this.currentManager.service.selectedPeriod = toDayPeriod(date);
  }

  hasNewMarketWatch() {
    return this.marketWatchManagers.some((manager) => !manager.marketWatch)
  }

  newMarketWatch() {
    if (this.marketWatchManagers.length > MARKET_WATCHES_LIMIT || this.hasNewMarketWatch()) return;
    this.addManager({service: new TendersSearchService(this.apiTenderService), editMode: true, creationMode: true})
    this.setCurrentManager(this.marketWatchManagers[this.marketWatchManagers.length - 1]);
    this.currentManager.newMarketWatchName = MW_NEW_NAME_DEFAULT_VALUE;
    this.onDaySelect(new Date());
    this.currentManager.service.sort = { dir: SortDirEnum.DESC, field: SortFieldEnum.RELEVANCE };
  }

  removeCurrentManager() {
    this.removeManager(this.currentManager);
    if (!this.marketWatchManagers.length) this.newMarketWatch();
  }

  removeManager(manager: MarketWatchManager) {
    this.unregisterService(manager.service);
    manager.service.destroy();
    this.marketWatchManagers = this.marketWatchManagers.filter((man) => man !== manager);
    if (this.currentManager === manager)
      this.setCurrentManager(
        this.marketWatchManagers.includes(this.previousManager) ?
          this.previousManager :
          this.marketWatchManagers[0]
      );
  }

  updateTenderInRegisteredServices(tenderId: string, attributes: Partial<Tender>) {
    this.activeSearchServices.forEach((searchService) => {
      searchService.tenders?.forEach((tender) => {
        if (tender.id === tenderId) {
          Object.assign(tender, attributes);
        }
      });
    });
  }

  registerService(service: TendersSearchService) {
    this.activeSearchServices.push(service);
  }

  unregisterService(service: TendersSearchService) {
    this.activeSearchServices = this.activeSearchServices.filter(activeService => activeService !== service);
  }

}

