import { Period } from "../../tenders/services/tenders-search.service";

export function isSameDay(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isSamePeriod(period1: Period | null, period2: Period | null) {
  if (!period1 || !period2) return false;
  return isSameDay(new Date(period1.from), new Date(period2.from)) && isSameDay(new Date(period1.to), new Date(period2.to));
}

export function getTodayDate() {
  return new Date().toISOString();
}

export function toDayPeriod(date: Date): Period {
  const from = new Date(date);
  from.setUTCHours(6, 0, 0, 0);
  from.setDate(from.getDate() - 1);
  const to = new Date(date);
  to.setUTCHours(6, 0, 0, 0)
  return {
    from: from.toISOString(),
    to: to.toISOString()
  }
}
