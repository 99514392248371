import { DbGridAnswer, GridAnswer } from './grid-answer';
import { DbDceDocumentUid, DceDocumentUid } from './dce-document-uid';


export enum GridQuestionTypeEnum {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export enum GridQuestionFormActionEnum {
    ADD = 'add',
    EDIT = 'edit'
}

export interface DbGridQuestion {
  project_uid: string;
  question_id: number;
  displayed_name: string;
  name: string;
  documents_uids: DbDceDocumentUid[];
  answer: DbGridAnswer | null;
  is_generation_finished: boolean;
  type: GridQuestionTypeEnum,
  is_generation_failed: boolean;
}

export class GridQuestion {
  projectUid: string;
  questionId: number;
  displayedName: string;
  name: string;
  documentUids: DceDocumentUid[];
  answer: GridAnswer | null;
  isGenerationFinished: boolean;
  type: GridQuestionTypeEnum;
  isGenerationFailed: boolean;

  constructor(dbGridQuestion: DbGridQuestion) {
    this.projectUid = dbGridQuestion.project_uid;
    this.questionId = dbGridQuestion.question_id;
    this.displayedName = dbGridQuestion.displayed_name;
    this.name = dbGridQuestion.name
    this.documentUids = dbGridQuestion.documents_uids.map((document) => new DceDocumentUid(document));
    this.isGenerationFinished = dbGridQuestion.is_generation_finished;
    this.answer = dbGridQuestion.answer ? new GridAnswer(dbGridQuestion.question_id, dbGridQuestion.answer) : null;
    this.type = dbGridQuestion.type;
    this.isGenerationFailed = dbGridQuestion.is_generation_failed;
  }
}
export const CCTP_DISPLAYED_NAME = 'Aperçu du CCTP';
